<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <!-- brand logo -->
      <router-link
        to="/"
        class="brand-logo d-flex align-center"
      >
        <v-img
          :src="appLogo"
          max-height="30px"
          max-width="30px"
          alt="logo"
          contain
          class="me-3 "
        ></v-img>

        <h2 class="text--primary">
          {{ appName }}
        </h2>
      </router-link>
      <!--/ brand logo -->

      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
          <div class="auth-illustrator-wrapper">
            <div class="d-flex justify-center align-center h-full pa-16">
              <v-img
                contain
                max-width="60%"
                :src="require('@/assets/images/illustrations/login.png')"
              ></v-img>
            </div>
          </div>
        </v-col>

        <v-col
          lg="4"
          class="d-flex align-center auth-bg pa-10 pb-0"
        >
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
              lg="12"
              class="mx-auto"
            >
              <v-card flat>
                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--primary mb-2">
                    Welcome to Clericle! 👋🏻
                  </p>
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                  <v-form
                    ref="loginForm"
                    @submit.prevent="login"
                  >
                    <v-text-field
                      v-model="email"
                      outlined
                      label="Email"
                      placeholder="email"
                      :error-messages="errorMessages.email"
                      :rules="[validators.required, validators.emailValidator]"
                      hide-details="auto"
                      class="mb-6"
                    ></v-text-field>

                    <v-text-field
                      v-model="password"
                      outlined
                      :type="isPasswordVisible ? 'text' : 'password'"
                      label="Password"
                      placeholder="Password"
                      :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                      :error-messages="errorMessages.password"
                      :rules="[validators.required]"
                      hide-details="auto"
                      class="mb-2"
                      @click:append="isPasswordVisible = !isPasswordVisible"
                    ></v-text-field>

                    <div class="d-flex align-center justify-space-between flex-wrap">
                      <!-- forget link -->
                      <router-link
                        :to="{name:'auth-forgot-password'}"
                        class="ms-3"
                      >
                        Forgot Password?
                      </router-link>
                    </div>

                    <v-btn
                      block
                      color="primary"
                      type="submit"
                      class="mt-6"
                      :loading="isLoading"
                      :disabled="isLoading"
                    >
                      Login
                    </v-btn>
                  </v-form>
                </v-card-text>

                <!-- create new account  -->
                <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
                  <p class="mb-0 me-2">
                    New on our platform?
                  </p>
                  <router-link :to="{name:'auth-register'}">
                    Create an account
                  </router-link>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import {
  ref,
  getCurrentInstance,
  inject,
  onBeforeMount,
} from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import { axios } from '@axios'
import { useRouter } from '@core/utils'
import themeConfig from '@themeConfig'
import encryptionService from '@/services/encryption.service'

export default {
  setup() {
    // Initializers
    const loginForm = ref(null)
    const store = inject('store')
    const snackbarService = inject('snackbarService')

    const vm = getCurrentInstance().proxy
    const { route, router } = useRouter()

    // Properties
    const isPasswordVisible = ref(false)
    const email = ref(null)
    const password = ref(null)
    const isLoading = ref(false)
    const errorMessages = ref([])

    // Methods
    const login = () => {
      isLoading.value = true
      if (!route.value.query.email) {
        const isFormValid = loginForm.value.validate()

        if (!isFormValid) {
          isLoading.value = false

          return
        }
      }

      axios
        .post('/auth/sign_in', { email: email.value, password: password.value })
        .then(response => {
          const { data } = response.data
          const { permissions, modules } = data

          vm.$ability.update(permissions)
          const encyrptedPermissions = encryptionService.encrypt(JSON.stringify(permissions))
          const encryptedModules = encryptionService.encrypt(JSON.stringify(modules))
          localStorage.setItem('userAbility', encyrptedPermissions)
          localStorage.setItem('organizationModules', encryptedModules)

          delete data.permissions
          store.dispatch('setSettings', data)
          store.dispatch('setOrganization', data.organization)

          router.push('/')
        })
        .catch(error => {
          isLoading.value = false
          errorMessages.value = error.response.data.errors
          snackbarService.error('Incorrect password or email. Please try again')
        })
    }
    const queryToLogin = () => {
      email.value = route.value.query.email
      password.value = route.value.query.password
      login()
    }

    // BeforeMount
    onBeforeMount(() => {
      if (route.value.query.email) queryToLogin()
    })

    return {
      // Initializers
      loginForm,

      // Properties
      isPasswordVisible,
      email,
      password,
      isLoading,
      errorMessages,

      // Others
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      validators: {
        required,
        emailValidator,
      },
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      // Methods
      login,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
